import React from 'react';
import {
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text, Modal, Button } from 'hudl-uniform-ui-components';

const snippet = require('raw-loader!../../../../data/snippets/modal.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, Modal: Modal, Button: Button }}
        code={snippet}
        platform="react"
        gitHubLink="modal"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="isOpen" types={['bool']}>
            <Text>Determines whether or not the modal is displayed.</Text>
          </PropListItem>

          <PropListItem name="title" types={['string']}>
            <Text>What is displayed in the header as the modal title.</Text>
          </PropListItem>

          <PropListItem name="onClose" types={['func']}>
            <Text>
              Function called when the modal is triggered to close. This is
              called if and when the Esc key is pressed, the overlay is clicked,
              the (optional) Cancel button is clicked, or the X is clicked,
              triggering the modal to close. This is where you might update the
              isOpen value to false.
            </Text>
          </PropListItem>

          <PropListItem name="actions" types={['arrayOf', 'button']}>
            <Text>
              One or more{' '}
              <Link href="/components/buttons/button" isDesignCodeLink>
                Button
              </Link>{' '}
              components rendered as actions in the footer. These Buttons are
              rendered right to left in the order they’re provided.
            </Text>
          </PropListItem>

          <PropListItem name="onAfterOpen" types={['func']}>
            <Text>Called when the modal is opened.</Text>
          </PropListItem>

          <PropListItem name="size" types={['oneOf', 'string']}>
            <Text>
              Determines the size of the modal. This defaults to default, but if
              you need the modal to be wider, set it to large. This will not
              affect the height of the modal, only the width. The height of the
              modal is determined by the height of its content. Once its content
              reaches a certain height, the modal will no longer become taller.
              Instead, content will be scrollable.
            </Text>
            <List type="unordered">
              <li>
                <code>default</code> (default)
              </li>
              <li>
                <code>large</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="supportingText" types={['string']}>
            <Text>Rendered on the left side of the footer.</Text>
          </PropListItem>

          <PropListItem name="label" types={['string']}>
            <Text>
              Value used for the aria-label accessibility tag on the Modal
              element. Defaults to "Hudl Modal".
            </Text>
          </PropListItem>

          <PropListItem name="showCancelAction" types={['bool']}>
            <Text>
              Whether or not to show the cancel button. The cancel button will
              be shown if any actions are provided. If no actions are provided
              you can set this to false and then the Cancel action will be
              hidden as well.
            </Text>
          </PropListItem>

          <PropListItem name="cancelText" types={['any']}>
            <Text>
              The translated text shown for the Cancel button. If no translated
              text is provided, "Cancel" will display, by default.
            </Text>
          </PropListItem>

          <PropListItem name="parentSelector" types={['func']}>
            <Text>
              A function that will be called to get the parent element that the
              modal will be attached to. By default, it will be attached to the{' '}
              <code>body</code> tag.
            </Text>
          </PropListItem>

          <PropListItem name="qaId" types={['string']}>
            <Text>
              A unique string that appears as a data attribute in the rendered
              code, serving as a hook for automated tests.
            </Text>
          </PropListItem>

          <PropListItem name="className" types={['string']}>
            <Text>A custom class to be passed onto the main body of the modal.</Text>
          </PropListItem>
        </PropList>
      </Section>

      <Section title="Notes">
        <List type="unordered">
          <li>All modal buttons are rendered as Medium size.</li>
          <li>
            The header and footer will remain fixed, even with scrollable
            content.
          </li>
        </List>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
